/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
:root {
  --bs-blue: #00a859;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #FF0000;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: var(--bs-primary);
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #A52A2A;
  --bs-primary: #00a859;
  --bs-secondary: #6c757d;
  --bs-success: #008000;
  --bs-info: #0dcaf0;
  --bs-warning: #FFA500;
  --bs-danger: #FF0000;
  --bs-light: #f8f9fa;
  --bs-dark: #A52A2A;
  --bs-primary-rgb: 0, 168, 89;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 0, 128, 0;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 165, 0;
  --bs-danger-rgb: 255, 0, 0;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 165, 42, 42;
  --bs-primary-text-emphasis: #101010;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #00a859;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #00a859;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000000;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #000000;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #A52A2A;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #008000;
  --bs-form-valid-border-color: #008000;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
  --bs-btn-disabled-opacity: .4 !important;
}

body {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: var(--bs-primary);
  --bs-pagination-active-border-color: var(--bs-primary);
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.btn {
  border-radius: 12px !important;
  font-size: 14px;

}

.btn-pill {
  border-radius: 40px !important;
}

.btm-sm {
  font-size: 12px !important;
  padding: 6px 12px !important;
}

.btn-xs {
  font-size: 16px !important;
  padding: 0px 4px !important;
}

.btn-block {
  width: 100% !important;
  padding: 16px;
}

.btn-half_block {
  width: 50% !important;
  padding: 14px;
  // text-align: center;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00a859;
  --bs-btn-border-color: #00a859;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00a859d7;
  --bs-btn-hover-border-color: #00a859;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00a859;
  --bs-btn-active-border-color: #00a859;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00a859;
  --bs-btn-disabled-border-color: #00a859;

}

.btn-tertiary {
  --bs-btn-color: #000;
  --bs-btn-bg: #0000000d;
  --bs-btn-border-color: #00000000;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0000000d;
  --bs-btn-hover-border-color: #12121200;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #1212120b;
  --bs-btn-active-border-color: #12121200;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3131310d;
  --bs-btn-disabled-border-color: #00000000;

}

.btn-secondary {
  --bs-btn-color: #00a859;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: #00a859;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #00a859;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #00a859;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #00a859;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00a859;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00a859;

}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ff0000;
  --bs-btn-border-color: #FF0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FF0000;
  --bs-btn-disabled-border-color: #FF0000;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #191919;
}

.search-input {
  padding: 10px 15px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  background-color: #fff;

  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: #6c757d;
  }
}

.text-muted {
  opacity: .8 !important;
}

.form-group {
  margin-bottom: 0.9rem !important;
}

.form-control,
.form-select {
  padding: 0.75rem 0.5rem;
  font-size: 14px !important;
}

.form-control:focus,
.form-select:focus {
  border-color: #000000;
  box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: var(--bs-primary) !important;
  color: #fff;
  font-size: 1em;
}

div:where(.swal2-icon).swal2-success {
  border-color: #fff !important;
  color: #fff !important;
}

div:where(.swal2-icon).swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: .25em solid rgb(0, 0, 0);
  border-radius: 50%;
  background: #00a8590e !important;
}

.text-bg-success {
  color: var(--bs-success) !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, .2)) !important;
}

.text-bg-secondary {
  color: var(--bs-secondary) !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, .2)) !important;
}

.text-bg-danger {
  color: var(--bs-danger) !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, .2)) !important;
}

.text-bg-warning {
  color: var(--bs-warning) !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, .2)) !important;
}

.text-bg-indigo {
  color: var(--bs-indigo) !important;
  background-color: RGBA(var(--bs-indigo-rgb), var(--bs-bg-opacity, .2)) !important;
}

.text-bg-primary {
  color: var(--bs-primary) !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, .2)) !important;
}

// ul ul a {
//   // font-size: 0.9em !important;
//   // padding-left: 30px !important;
//   background: #fff;
// }
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.spec-btn {
  font-weight: 600;
}
.cursor-pointer {
  cursor: pointer;
}
.form-area {
  label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 7px;
  }
  .form-control {
    height: 45px !important;
    border-radius: 10px !important;
  }
}
.table {
  th {
    color: #344054;
    font-weight: 500;
    background-color: #f9fafb;
    padding: 0.75rem 1rem;
  }

  td {
    color: #475467;
    padding: 1rem;
    vertical-align: middle;
  }
}
// Add these to your SCSS/CSS file
.badge {
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;

  // Default/Basic Badges
  &.badge-primary {
    color: #fff;
    background-color: #0d6efd;
  }

  &.badge-secondary {
    color: #fff;
    background-color: #6c757d;
  }

  &.badge-success {
    color: #fff;
    background-color: var(--bs-primary);
  }

  &.badge-danger {
    color: #fff;
    background-color: #dc3545;
  }

  &.badge-warning {
    color: #000;
    background-color: #ffc107;
  }

  &.badge-info {
    color: #000;
    background-color: #0dcaf0;
  }

  &.badge-light {
    color: #000;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
  }

  &.badge-dark {
    color: #fff;
    background-color: #212529;
  }

  // Soft/Pastel Badges
  &.badge-soft-primary {
    color: #0d6efd;
    background-color: #e7f0ff;
  }

  &.badge-soft-secondary {
    color: #6c757d;
    background-color: #f0f1f2;
  }

  &.badge-soft-success {
    color: var(--bs-primary);
    background-color: #e8f6f0;
  }

  &.badge-soft-danger {
    color: #dc3545;
    background-color: #fcedee;
  }

  &.badge-soft-warning {
    color: #997404;
    background-color: #fff8e1;
  }

  &.badge-soft-info {
    color: #0dcaf0;
    background-color: #e7fafd;
  }

  // Outlined Badges
  &.badge-outline-primary {
    color: #0d6efd;
    background-color: transparent;
    border: 1px solid #0d6efd;
  }

  &.badge-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    border: 1px solid #6c757d;
  }

  &.badge-outline-success {
    color: var(--bs-primary);
    background-color: transparent;
    border: 1px solid var(--bs-primary);
  }

  &.badge-outline-danger {
    color: #dc3545;
    background-color: transparent;
    border: 1px solid #dc3545;
  }

  &.badge-outline-warning {
    color: #ffc107;
    background-color: transparent;
    border: 1px solid #ffc107;
  }

  &.badge-outline-info {
    color: #0dcaf0;
    background-color: transparent;
    border: 1px solid #0dcaf0;
  }

  // Status Badges
  &.badge-status-active {
    color: #027a48;
    background-color: #ecfdf3;
  }

  &.badge-status-pending {
    color: #b54708;
    background-color: #fffaeb;
  }

  &.badge-status-inactive {
    color: #344054;
    background-color: #f2f4f7;
  }

  &.badge-status-suspended {
    color: #c01048;
    background-color: #fef3f2;
  }

  // Modern/Gradient Badges
  &.badge-gradient-primary {
    color: #fff;
    background: linear-gradient(45deg, #0d6efd, #0a58ca);
  }

  &.badge-gradient-success {
    color: #fff;
    background: linear-gradient(45deg, var(--bs-primary), #146c43);
  }

  &.badge-gradient-danger {
    color: #fff;
    background: linear-gradient(45deg, #dc3545, #b02a37);
  }

  // Pill Badges
  &.badge-pill {
    border-radius: 50rem;
  }

  // Custom Styles
  &.badge-dot {
    position: relative;
    padding-left: 24px;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: currentColor;
    }
  }
}

// Size Variations
.badge-sm {
  padding: 4px 8px;
  font-size: 11px;
}

.badge-lg {
  padding: 8px 16px;
  font-size: 14px;
}


.modal {
  .modal-content {
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid #eee;
    background-color: #f8f9fa;
    border-radius: 12px 12px 0 0;

    .modal-title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .btn-close {
      padding: 1.25rem;
      margin: -1rem -1rem -1rem auto;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .modal-body {
    padding: 1.5rem;
  }

  .modal-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid #eee;
    background-color: #f8f9fa;
    border-radius: 0 0 12px 12px;

    .btn {
      min-width: 100px;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

// Modal sizes
.modal-sm {
  max-width: 400px;
}

.modal-md {
  max-width: 600px;
}

.modal-lg {
  max-width: 800px;
}

// Optional: Animation for modal
.modal {
  &.fade .modal-dialog {
    transition: transform 0.2s ease-out;
    transform: scale(0.95);
  }

  &.show .modal-dialog {
    transform: none;
  }
}
.text-mute {
  color: gray;
}